import React, {
  Suspense,
  lazy,
  useCallback,
  useRef,
  useState,
  useEffect,
} from "react";
import { useHistory } from "react-router-dom";
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { errorHandler, isProPlusUser } from "../../../../helper-methods";
// import SkeletonLoading from "../../components/Skeletons/SkeletonLoading";
// import useScreenWidth from "../../components/HelpModule/useScreenWidth";
import PersonalWebsite from "./personalWebsiteMangement/PersonalWebsite";
import { getAgentProfileDetail } from "../../../../http/http-calls";
import PersonalWebsiteWarningModal from "../../components/Agents/PersonalWebsiteWarningModal";

// const PersonalWebsiteSetp1 = lazy(() =>
//   import("../../components/Agents/PersonalWebsiteSetp1")
// );
const PersonalWebsiteSetp2 = lazy(() =>
  import("../../components/Agents/PersonalWebsiteSetp2")
);
const PersonalWebsiteSetp3 = lazy(() =>
  import("../../components/Agents/PersonalWebsiteSetp3")
);

const PersonalWebsiteManagement = () => {
  const history = useHistory();
  // const step1Ref = useRef();
  const step2Ref = useRef();
  const step3Ref = useRef();

  // const { isForMobile } = useScreenWidth();

  const [activeTab, setActiveTab] = useState("1");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [loading, setloading] = useState({
    stepLoading: false,
  });

  const [websiteWarningModal, setWebsiteWarningModal] = useState({
    isOpen: false,
    data: null,
  });

  const _togglewebsiteWarningModal = (isOpen = false, data = null) => {
    setWebsiteWarningModal({ isOpen, data });
  };

  const _manageLoadingState = useCallback((key = "", value = false) => {
    setloading((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const [websiteLaunchedModal, setWebsiteLaunchedModal] = useState({
    isOpen: false,
    data: null,
  });

  const _togglewebsiteLaunchedModal = (isOpen = false, data = null) => {
    setWebsiteLaunchedModal({ isOpen, data });
  };

  const toggle = (tab) => {
    // dont' let users to move to other steps if domain setup is not complete in step 1
    if (Number(activeTab) === 1 && !step2Ref?.current?.isDomainSetupDone) {
      errorHandler({ reason: "Please enter domain details" })
      return;
    }

    // if (Number(tab) === 3 && Number(tab) !== Number(activeTab)) {
    //   showToast("Setting up your website builder... Hang tight!", "success")
    // }

    if (activeTab !== tab) setActiveTab(tab);
  };

  // function to increment step based on 'increment', default increment -> 1
  const _stepIncrement = (activeTab, incrementBy = 1) => {
    //  can't go forward than step 3
    if (Number(activeTab) === 3) {
      return;
    }

    let temp = Number(activeTab) + incrementBy;
    setActiveTab(String(temp));
  };

  // do some tasks like api calling etc. before moving to next step
  const _moveToNextStep = async (activeTab) => {
    if (Number(activeTab) === 1) {
      try {
        const isDomainNamePresent = await step2Ref.current.isDomainNamePresent;
        // can't move past step2, unless user enter domain details in step2
        if (!isDomainNamePresent) {
          errorHandler({ reason: "Please enter domain details" });
          return;
        }
        _stepIncrement(activeTab, 1);
      } catch (err) {
        errorHandler(err);
      }
    } else if (Number(activeTab) === 2) {
      try {
        const isSuccess = await step3Ref.current._onSubmit();

        if (isSuccess) {
          // _togglewebsiteLaunchedModal(true);
          _stepIncrement(activeTab, 1);
          // showToast("Setting up your website builder... Hang tight!", "success")
        }
      } catch (err) {
        errorHandler(err);
      }
    } else {
      _stepIncrement(activeTab, 1);
    }
  };

  // move 1 step behind
  const _moveToPrevStep = async (activeTab) => {
    if (Number(activeTab) === 1) {
      return;
    }

    if (Number(activeTab) === 3) {
      setIsFormSubmitted(false);
    }

    let temp = Number(activeTab) - 1;
    setActiveTab(String(temp));
  };

  const _checkForKYCAndStandardFee = async () => {
    try {
      const res = await getAgentProfileDetail();

      if (
        res?.agent?.kyc &&
        !res?.agent?.kyc?.paymentEnabled &&
        !res?.agent?.kyc?.payoutEnabled
        //   ||
        // !res?.agent?.isAtleastOneStandardFeesAndWitnessFeeAvailable
      ) {
        _togglewebsiteWarningModal(true, res?.agent);
        return;
      }
    } catch (err) {
      errorHandler(err);
      history.push("/agent/profile");
    }
  };

  useEffect(() => {
    // only let pro-plus users access this page
    if (!isProPlusUser()) {
      history.goBack();
      return;
    }

    _checkForKYCAndStandardFee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="content">
        {/* <div className="responsiveTitle">
          <h2>Personal Website Management</h2>
        </div> */}

        <div className="personalWebsiteSetps">
          <Nav pills className="stepProcess">
            <NavItem>
              <NavLink
                className={{ active: +activeTab >= 1 }}
                onClick={() => {
                  toggle("1");
                }}
              >
                <div
                  className={`stepCount ${Number(activeTab) > 1 ? "success" : null
                    }`}
                >
                  {Number(activeTab) > 1 ? (
                    <img
                      src={require("../../../../assets/img/check.png")}
                      alt="icon"
                    />
                  ) : (
                    1
                  )}
                </div>
                <span className="stepTitle">Step 1</span>
                {/* <span>Basic Detail</span> */}
                <span>Domain Setting</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={{ active: +activeTab >= 2 }}
                onClick={() => {
                  toggle("2");
                }}
              >
                <div
                  className={`stepCount ${Number(activeTab) > 2 ? "success" : null
                    }`}
                >
                  {Number(activeTab) > 2 ? (
                    <img
                      src={require("../../../../assets/img/check.png")}
                      alt="icon"
                    />
                  ) : (
                    2
                  )}
                </div>
                <span className="stepTitle">Step 2</span>
                <span>Add Agent</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={{ active: +activeTab >= 3 }}
                onClick={() => {
                  toggle("3");
                }}
              >
                <div
                  className={`stepCount ${isFormSubmitted ? "success" : null}`}
                >
                  {isFormSubmitted ? (
                    <img
                      src={require("../../../../assets/img/check.png")}
                      alt="icon"
                    />
                  ) : (
                    3
                  )}
                </div>
                <span className="stepTitle">Step 3</span>
              </NavLink>
            </NavItem>
          </Nav>
          {/* {loading?.stepLoading ? (
            <SkeletonLoading
              type="card"
              count={9}
              height={isForMobile ? 50 : 50}
              width={isForMobile ? 350 : 1500}
            />
          ) : (
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Suspense fallback={<></>}>
                  {/* <PersonalWebsiteSetp1
                    manageLoadingState={_manageLoadingState}
                    ref={step1Ref}
                  /> */}
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              {Number(activeTab) === 1 ? <Suspense fallback={<></>}>
                <PersonalWebsiteSetp2
                  manageLoadingState={_manageLoadingState}
                  ref={step2Ref}
                />
              </Suspense> : null}
            </TabPane>
            <TabPane tabId="2">
              <Suspense fallback={<></>}>
                {Number(activeTab) === 2 ? (
                  // <PersonalWebsiteSetp2
                  //   manageLoadingState={_manageLoadingState}
                  //   ref={step2Ref}
                  // />
                  <PersonalWebsiteSetp3
                    manageLoadingState={_manageLoadingState}
                    ref={step3Ref}
                    setIsFormSubmitted={setIsFormSubmitted}
                    websiteLaunchedModal={websiteLaunchedModal}
                    togglewebsiteLaunchedModal={_togglewebsiteLaunchedModal}
                  />
                ) : null}
              </Suspense>
            </TabPane>
            <TabPane tabId="3">
              <Suspense fallback={<></>}>
                {Number(activeTab) === 3 ? (
                  // <PersonalWebsiteSetp3
                  //   manageLoadingState={_manageLoadingState}
                  //   ref={step3Ref}
                  //   setIsFormSubmitted={setIsFormSubmitted}
                  //   websiteLaunchedModal={websiteLaunchedModal}
                  //   togglewebsiteLaunchedModal={_togglewebsiteLaunchedModal}
                  // />
                  <PersonalWebsite
                    websiteLaunchedModal={websiteLaunchedModal}
                    togglewebsiteLaunchedModal={_togglewebsiteLaunchedModal}
                  />
                ) : null}
              </Suspense>
            </TabPane>
          </TabContent>
          {/* )} */}

          <div className="d-flex align-items-center justify-content-center">
            {Number(activeTab) !== 3 ? (
              <>
                {Number(activeTab) !== 1 ? (
                  <Button
                    color="primary"
                    outline
                    onClick={() => _moveToPrevStep(activeTab)}
                  >
                    Previous
                  </Button>
                ) : null}
                <Button
                  color="primary"
                  onClick={() => _moveToNextStep(activeTab)}
                  disabled={loading?.stepLoading}
                >
                  {loading?.stepLoading ? (
                    <i className="fa fa-spinner fa-spin mr-2" />
                  ) : null}
                  {Number(activeTab) < 3 ? "Save & Next" : "Save"}
                </Button>
              </>
            ) : null}
          </div>
        </div>
      </div>

      <Suspense fallback={<></>}>
        <PersonalWebsiteWarningModal
          isOpen={websiteWarningModal?.isOpen}
          data={websiteWarningModal?.data}
          toggle={_togglewebsiteWarningModal}
        />
      </Suspense>
    </>
  );
};

export default PersonalWebsiteManagement;
